<template>
  <div>
    <b-card bg-variant="light" header="Datos del Alumno" header-tag="header" class="mt-4">
      <!-- <h5>{{this.estadotransaccion}} {{this.$route.path}}</h5> -->
      <h5> {{ alumno.DisplayNivelEnseñanzaSinCuise }}</h5>
      <h2>{{ alumno.DisplayName }}</h2>
      <h5>Código de alumno: {{ alumno.DisplayCodigoAlumnoNivel }}</h5>
      <h5>Código link pagos: {{ alumno.DisplayCodigoLinkPagosNivel }}</h5>
      <h5>{{ this.displayDebitoDirecto }}</h5>
      <h5>{{ !this.tienematricula ? "No tiene matricula asignada para el ciclo lectivo " +  this.ciclolectivo : "" }}</h5>
      <slot></slot>
      <router-link to="/buscaralumno" v-slot="{href, route, navigate}" >
          <b-button size="sm" :href="href" variant="info" @click="navigate" class='mr-2'>
            {{ getIsMobile ?  "Buscar" : route.name }}
          </b-button>
      </router-link>
      <router-link v-if="this.tienematricula" to="/vercupones" v-slot="{href, route, navigate}"  >
          <b-button size="sm" :href="href" variant="info"  @click="navigate" class='mr-2'>
            {{ getIsMobile ?  "Ver Cupones" : route.name }}
          </b-button>
      </router-link>

      <!-- <router-link v-if="((this.$route.path !== '/formapago' && this.$route.path !== '/registrarpago') && this.cuponesseleccionados.length > 0 && this.tienematricula)" to="/formapago" v-slot="{href, route, navigate}"  >
          <b-button size="sm" :href="href" variant="info"  @click="navigate" class='mr-2'>
            {{ getIsMobile ?  "Forma de Pago" : route.name }}
          </b-button>
      </router-link> -->
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "CardAlumno",
  props: {
      alumno: Object
  },
  watch: {

  },
  computed: {
    ...mapGetters(["getIsMobile"]),
    displayDebitoDirecto: function() {
      return this.alumno ? (this.alumno.DebitoDirecto
        ? this.mensajeDebito
        : this.mensajeNoDebito) : "NULL";
    },
    ...mapFields(['estadotransaccion','cuponesseleccionados', 'tienematricula', 'ciclolectivo']),
    añoActual: function(){
      return new Date().getFullYear();
    },
    listacupones: function(){
      var lista = this.alumno ? this.alumno.Cupones.map(({Id, Estado, Importe, TipoConceptoCobro, PeriodoAño}) => ({Id, Estado, Importe, TipoConceptoCobro, PeriodoAño})) : [];
      return lista;
    }
  },
  components: {
  },
  data() {
    return {
      mensajeDebito: "Adherido al débito automático",
      mensajeNoDebito: "No esta adherido al débito automático"
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
