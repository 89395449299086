<template>
  <div>
    <card-caja-municipal  v-if="this.alumno && this.formapago === 0" logo="img/cm60.png">

    </card-caja-municipal>
    <card-transferencia  v-if="this.alumno && this.formapago === 1" logo="img/tr128.png">

    </card-transferencia>

    <card-deposito  v-if="this.alumno && this.formapago === 2" logo="img/dep128.png">

    </card-deposito>
    <card-mercado-pago  v-if="this.alumno && this.formapago === 3" logo="img/mp128.png">

    </card-mercado-pago>
    <card-debito-directo v-if="this.alumno && this.formapago === 4" logo="img/dd80.png">
    </card-debito-directo>

    <card-link-pagos v-if="this.alumno && this.formapago === 5" logo="img/lnk80.png">
    </card-link-pagos>

    <card-pago-facil v-if="this.alumno && this.formapago === 6" logo="img/pf50.png">
    </card-pago-facil>
  </div>
</template>
<script>
import CardMercadoPago from '@/components/cards/CardMercadoPago.vue';
import CardCajaMunicipal from '@/components/cards/CardCajaMunicipal.vue';
import CardTransferencia from '@/components/cards/CardTransferencia.vue';
import CardDeposito from '@/components/cards/CardDeposito.vue';
import CardDebitoDirecto from '@/components/cards/CardDebitoDirecto.vue';
import CardLinkPagos from '@/components/cards/CardLinkPagos.vue';
import CardPagoFacil from '@/components/cards/CardPagoFacil.vue';

import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "FormFormaPago",
  props: {
    titulo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsAlumno(['alumno']),
    ...mapFieldsPago(['formapago']),
  },
  components: {
    CardCajaMunicipal,
    CardTransferencia,
    CardDeposito,
    CardMercadoPago,
    CardDebitoDirecto,
    CardLinkPagos,
    CardPagoFacil
  },
  data() {
    return {
    };
  },
  methods: {
    showfp: function(fp){
      console.log(fp);
      this.formapago = fp;
      // if(fp === 0){
        
      // }
    }
  },
  mounted() {
  }
}
</script>